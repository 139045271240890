<template>
  <section class="coupon-setting" :v-loading="loading">
    <PageTitle
      title="票券模組參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <section class="section-block">
      <SectionTitle title="票券設定" @edit="showDialog = true" />
      <BaseElForm
        v-model="formData"
        label-position="left"
        label-width="250px"
      >
        <BaseElFormItem label="票券顯示名稱" prop="displayName">
          <div class="text-[#5E5E5E]">{{ formData.displayName }}</div>
        </BaseElFormItem>
        <BaseElFormItem label="票券到期通知發送天數" props="expireNotificationBefore">
          <div class="text-[#5E5E5E]">{{ formData.expireNotificationBefore }}天</div>
        </BaseElFormItem>
      </BaseElForm>
    </section>
    <CouponSettingsEditModal
      v-if="showDialog"
      :configData="configData"
      @close="showDialog = false"
      @refresh="getCouponConfig"
    />
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { GetCouponConfig, UpdateCouponConfig } from '@/api/coupon'
import CouponSettingsEditModal from './components/CouponSettingsEditModal.vue'
import store from '@/store'

export default defineComponent({
  name: 'CouponSettings',
  components: { CouponSettingsEditModal },
  setup (props) {
    const loading = ref(false)
    const formData = reactive({
      displayName: '',
      expireNotificationBefore: 7,
    })
    const showDialog = ref(false)
    const configData = ref({})
    const shopId = computed(() => store.getters.shop)

    const getCouponConfig = async () => {
      loading.value = true
      const [res, err] = await GetCouponConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
      formData.displayName = res.displayName || '票券'
      formData.expireNotificationBefore = res.expireNotificationBefore || 7
    }

    onMounted(() => {
      getCouponConfig()
    })
    return {
      loading,
      formData,
      showDialog,
      configData,
      getCouponConfig,
    }
  },
})
</script>
